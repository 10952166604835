<h2 mat-dialog-title>Delete Record</h2>
<mat-dialog-content class="mat-typography hide-scroll">
  <p>
    Sure to delete transaction: <b>{{data.accountName}}</b><small *ngIf="data.toName"> to</small><b> {{data.toName}}</b> with amount <b [ngClass]="{'minus': data.operator === 2}">{{data.amountFormatted}}</b>?
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true"
    (click)="submit()" cdkFocusInitial>Yes</button>
</mat-dialog-actions>
