export class Token {
    "uid": string;
    "email": string;
    "role": string;
    "name": string;
}

export class User {
    "created_at": Date;
    "deleted_at": Date;
    "updated_at": Date;
    "id_user": number;
    "name": string;
}