import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';
import { Account } from 'src/app/models/account.model';
import { CreateTransaction, Transaction } from 'src/app/models/transaction.model';
import { Token } from 'src/app/models/user.model';
import { AccountService } from 'src/app/services/account.service';
import { TransactionService } from 'src/app/services/transaction.service';
import { AccountService as AccountShared } from 'src/app/shared/account.service';

@Component({
  selector: 'app-dialog-add',
  templateUrl: './dialog-add.component.html',
  styleUrls: ['./dialog-add.component.scss']
})
export class DialogAddComponent implements OnInit {
  token: Token = new Token;

  accountFormControl = new FormControl('', [Validators.required]);
  operatorFormControl = new FormControl('Increase', [Validators.required]);
  amountFormControl = new FormControl('', [Validators.required]);
  toFormControl = new FormControl('');
  toEffectFormControl = new FormControl('Increase', [Validators.required]);
  noteFormControl = new FormControl('');
  dateFormControl = new FormControl('');
  idFormControl = new FormControl('');

  filteredAccounts: Observable<string[]> | undefined;
  filteredToAccounts: Observable<string[]> | undefined;
  filteredNotes: Observable<string[]> | undefined;
  transactions: Transaction[] = [];
  accounts: Account[] = [];
  accountNames: string[] = [];
  notes: string[] = [];
  operators: string[] = ["Increase", "Decrease"];

  formGroup: FormGroup = new FormGroup({});

  isEdit = false;

  radioDisabled = [false, false];

  invoices: any[] = [];
  invoiceAmount = 0;
  idInvoice = "";

  private _unsubscribeAll: Subject<any>;

  constructor(
    private accountService: AccountService,
    private transactionService: TransactionService,
    private accountShared: AccountShared,
    private dialog: MatDialogRef<DialogAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.token = this.accountShared.getAccount();

    this.transactions = data.transactions;
    this.accounts = data.accounts;
    this.accountNames = this.accounts.map(y => y.accountName);

    if (data.transaction) {
      data = data.transaction;
      this.isEdit = true;
      this.idFormControl.setValue(data.id_trx);
      this.accountFormControl.setValue(data.accountName);
      this.amountFormControl.setValue(data.amount);
      this.toFormControl.setValue(data.toName);
      this.noteFormControl.setValue(data.note);

      let operator = data.operator === 1 ? "Increase" : "Decrease";
      this.operatorFormControl.setValue(operator);

      let toEffect = data.toEffect === 1 ? "Increase" : "Decrease";
      this.toEffectFormControl.setValue(toEffect);

      // let date = new Date(data.created_at)
      // this.dateFormControl.setValue(date);
    } else {
      this.accountFormControl.setValue(data.search);
    }

    this.formGroup = new FormGroup({
      accountName: this.accountFormControl,
      amount: this.amountFormControl,
      note: this.noteFormControl,
      custom_date: this.dateFormControl,
      operator: this.operatorFormControl,
      toEffect: this.toEffectFormControl,
      toName: this.toFormControl,
      id_user: new FormControl(parseInt(this.token.uid)),
      id_trx: this.idFormControl,
    });

    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    //~ Diganti dengan @Input data
    // this.accountService.getAccountsByUser().subscribe(x => {
    //   this.accounts = x.data.accounts;
    //   this.accountNames = this.accounts.map(y => y.accountName);
    // });

    // this.notes = this.transactions.map(y => y.note).filter(x => x !== '');

    this.transactionService.getNotes().subscribe((notes) => {
      this.notes = notes.data;
    });

    this.filteredAccounts = this.accountFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.filteredToAccounts = this.toFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.filteredNotes = this.noteFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterNote(value || '')),
    );

    this.toFormControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this.checkMethod();
      });

    this.noteFormControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this.checkNominal();
      });

    this.accountFormControl.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(account => {
        this.checkMethod();
      });
  }

  setZero() {
    this.amountFormControl.setValue('');
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.accountNames.filter(account => account.toLowerCase().includes(filterValue));
  }

  private _filterNote(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.notes.filter(note => note.toLowerCase().includes(filterValue));
  }

  async checkMethod(ms: number = 0) {
    this.radioDisabled = [false, false];

    let operator = this.operatorFormControl.value === 'Increase' ? 1 : 2;

    this.transactionService.getMethod({
      accountName: this.accountFormControl.value,
      toName: this.toFormControl.value,
      operator: operator,
    }).subscribe(x => {
      if (this.toFormControl.value == 'ModalMamashii') {
        this.transactionService.getInvoices().subscribe((invoices) => {
          this.invoices = invoices;
          this.notes = this.invoices.map(x => x.name);
        });
      }

      let disable = x.data.length === 0 ? -1 : (x.data[0].toEffect === 2 ? 0 : 1);
      if (disable > -1) {
        let toEffect = x.data.length > 0 && x.data[0].toEffect === 2 ? 'Decrease' : 'Increase';
        let amount = x.data.length > 0 ? x.data[0].amount : null;
        this.toEffectFormControl.setValue(toEffect);

        //~ Prevent change when Edit
        if (!this.isEdit) {
          this.amountFormControl.setValue(amount);
        }

        this.radioDisabled[disable] = true;
      }
    });
  }

  async checkNominal(ms: number = 0) {
    if (this.toFormControl.value == 'ModalMamashii') {
      var name = this.noteFormControl.value;
      var found = this.invoices.filter(x => x.name == name)[0];
      this.invoiceAmount = found ? found['total_amount'] : 0;
      if (found) this.amountFormControl.setValue(this.invoiceAmount);

      this.idInvoice = found ? found['id'] : "";
    }
  }

  resetNominal() {
    this.amountFormControl.setValue(null);
  }

  saveOnEnter(event: any) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  save() {
    if (this.idInvoice != "") {
      this.transactionService.paidInvoice(this.idInvoice).subscribe();

      if (this.amountFormControl.value == this.invoiceAmount) {
        this.transactionService.paidFare(this.idInvoice).subscribe()
      }
    }

    this.operatorFormControl.setValue(this.operatorFormControl.value === "Increase" ? 1 : 2);
    this.toEffectFormControl.setValue(this.toEffectFormControl.value === "Increase" ? 1 : 2);

    if (this.dateFormControl.value) {
      let date = this.dateFormControl.value;
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      this.dateFormControl.setValue(year + '-' + month + '-' + dt);
    }

    this.dialog.close({
      isEdit: this.isEdit,
      data: this.formGroup.value
    });
  }

}
