export class Transaction {
    "id_trx": number;
    "accountName": string;
    "accountType": number;
    "amount": number;
    "amountFormatted": string;
    "created_at": Date;
    "updated_at": Date;
    "id_account": number;
    "id_user": number;
    "note": string;
    "operator": number;
    "toEffect": number;
    "toName": string;
    "toType": number;
    "to_account": number;
    "total": number;
}

export class CreateTransaction {
    "id_trx": number;
    "id_user": number;
    "accountName": string;
    "toName": string;
    "operator": number;
    "toEffect": number;
    "amount": number;
    "note": string;
    "ip_address"?: string;
}