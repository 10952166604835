import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountType } from 'src/app/models/account.model';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-dialog-edit-account',
  templateUrl: './dialog-edit-account.component.html',
  styleUrls: ['./dialog-edit-account.component.scss']
})
export class DialogEditAccountComponent implements OnInit {
  idFormControl = new FormControl('');
  accountFormControl = new FormControl('', [Validators.required]);
  typeFormControl = new FormControl('', [Validators.required]);

  formGroup: FormGroup = new FormGroup({});

  accountType: AccountType[] = [];

  selectedAccountType = "";

  constructor(
    private accountService: AccountService,
    private dialog: MatDialogRef<DialogEditAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.accountFormControl.setValue(data.account.accountName);
      this.typeFormControl.setValue(data.account.accountType);
      this.idFormControl.setValue(data.account.id_account);

      this.accountType = data.accountType.slice(1);

      var sortOrder = [1, 6, 2, 3, 4, 5, 9, 8, 7];
      this.accountType.sort(function (a, b) {
        return sortOrder.indexOf(a.accountType) - sortOrder.indexOf(b.accountType);
      });
    }

    this.formGroup = new FormGroup({
      accountName: this.accountFormControl,
      accountType: this.typeFormControl,
      id_account: this.idFormControl,
    });
  }

  ngOnInit(): void {
    this.selectedAccountType = this.formGroup.value["typeFormControl"];

    // this.accountService.getAccountType().subscribe(y => {
    //   this.accountType = y.data.slice(1);

    //   var sortOrder = [1, 6, 2, 3, 4, 5, 9, 8, 7];
    //   this.accountType.sort(function (a, b) {
    //     return sortOrder.indexOf(a.accountType) - sortOrder.indexOf(b.accountType);
    //   });
    // });
  }

  saveOnEnter(event: any) {
    if (event.keyCode === 13) {
      this.save();
    }
  }

  save() {
    this.dialog.close({
      data: this.formGroup.value
    });
  }

}
