import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/shared/account.service';

@Component({
  selector: 'app-gettoken',
  templateUrl: './gettoken.component.html',
  styleUrls: ['./gettoken.component.scss']
})
export class GettokenComponent implements OnInit {
  private token: string = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.token = params.code;

      if (this.token) {
        this.accountService.login(this.token);
      }

      if (this.accountService.isLoggedIn()) {
        this.router.navigate(['']);
      }
    });
  }

}
