<h2 mat-dialog-title>Edit Account</h2>
<mat-dialog-content class="mat-typography hide-scroll">
  <p>
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Account Name</mat-label>
      <input type="text" matInput [formControl]="accountFormControl"
        id="account_name"
        name="account_name"
        (keydown)="saveOnEnter($event)"
        placeholder="Ex. Cash">
      <mat-error *ngIf="accountFormControl.hasError('required')">
        This value is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </p>

  <p>
    <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Account Type</mat-label>
        <mat-select [formControl]="typeFormControl" [(value)]="selectedAccountType">
          <mat-option *ngFor="let type of accountType" value="{{type.accountType}}">
            {{type.typeName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" 
    [disabled]="formGroup.invalid"
    (click)="save()" cdkFocusInitial>Save</button>
</mat-dialog-actions>