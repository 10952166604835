import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Response } from '../models/response.model';
import { Account, AccountPlus, AccountType } from '../models/account.model';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  token = localStorage.getItem(environment.tokenName);
  id_user = jwt_decode<any>(this.token ?? "").uid;

  httpOptionsAuth = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`
    }),
    params: {}
  };

  constructor(
    private http: HttpClient
  ) { }

  getAccountType() {
    return this.http.get<Response<AccountType[]>>(`${environment.serviceURL}accountType`);
  }

  getAccountsByUser() {
    return this.http.get<Response<AccountPlus>>(`${environment.serviceURL}account`, this.httpOptionsAuth);
  }

  editAccount(account: Account) {
    return this.http.put<Response<Account>>(`${environment.serviceURL}account/${account.id_account}`, account, this.httpOptionsAuth);
  }
}
