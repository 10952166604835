<div style="color: white; font-weight: bold; display: flex; margin-left: 1em;">
  <div style="align-items: center; display: flex;"><span>Total Assets:&nbsp;</span><span [ngClass]="totalTrue < 0 ? 'minus' : 'plus'">{{hide ? total : totalTrueFormatted}}</span> </div>
  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide total'" [attr.aria-pressed]="hide" [matTooltip]="hide ? 'See Total Assets' : 'Hide Total Assets'">
    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>
</div>
<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false"
                       *ngFor="let a of accountType; let i=index">

    <mat-expansion-panel-header>
      <mat-panel-title>
        {{a.typeName}}
      </mat-panel-title>
      <mat-panel-description>
        
      </mat-panel-description>
    </mat-expansion-panel-header>

    <table mat-table [dataSource]="filterDataSource(a.accountType)">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <!-- <th mat-header-cell *matHeaderCellDef> ACCOUNT NAME </th> -->
        <td mat-cell *matCellDef="let element" (click)="showTransactions(element.accountName)" style="cursor: pointer;">
          {{element.accountName}}
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="total">
        <!-- <th mat-header-cell *matHeaderCellDef> TOTAL </th> -->
        <td mat-cell *matCellDef="let element" (click)="editAccount(element)" style="cursor: pointer;" [ngClass]="{'minus': element.totalAmount < 0}"> {{element.totalAmountFormatted}} </td>
      </ng-container>

      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr> -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-expansion-panel>
</mat-accordion>
