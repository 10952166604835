// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

let ssoURL = "https://dev2-sso.dhonstudio.com/";
let baseURL = "https://dev-kesku.dhonstudio.com/";
let serviceURL = "https://dev-service.muhammadr.com/kesku/";

if (window.location.href.includes('localhost')) {
  // ssoURL = "http://localhost:8081/";
  baseURL = "http://localhost:60431/";
  // serviceURL = "http://localhost:8080/";
}

export const environment = {
  production: false,

  ssoURL: ssoURL,
  baseURL: baseURL,
  serviceURL: serviceURL,

  serviceUsername: "admin",
  servicePassword: "admin",
  tokenRoute: "access",
  tokenName: "keskuAuthz",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
