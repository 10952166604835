import { AfterViewInit, Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Token, User } from 'src/app/models/user.model';
import { TransactionService } from 'src/app/services/transaction.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { Transaction } from 'src/app/models/transaction.model';
import { catchError, merge, startWith, switchMap, of as observableOf, map } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { AccountService as AccountShared } from 'src/app/shared/account.service';
import { TransactionsComponent } from './transactions/transactions.component';
import { AccountsComponent } from './accounts/accounts.component';
import { Account } from 'src/app/models/account.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  tabIndex = 0;

  token: Token = new Token;
  account: User = new User;
  name = "";

  filter = "";

  accounts: Account[] = [];

  @ViewChild(TransactionsComponent) transaction: TransactionsComponent | undefined;
  @ViewChild(AccountsComponent) accountCmp: AccountsComponent | undefined;

  constructor(
    private accountShared: AccountShared,
  ) {
    this.token = this.accountShared.getAccount();
    this.name = this.token.name;
  }

  ngOnInit(): void {
  }

  doFilter(event: string) {
    this.tabIndex = 0;
    this.transaction?.init(event);
  }

  initAccounts(event: Account[]) {
    this.accounts = event;
  }

  updateAccount() {
    this.accountCmp?.ngOnInit();
  }

  updateTransaction() {
    this.transaction?.init();
  }

  resetTab(value: any) {
    if (this.tabIndex != 0) this.filter = '';
    this.tabIndex = value.index;
  }

  logout() {
    this.accountShared.logout();
  }

}
