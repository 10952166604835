import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor() { }

  isLoggedIn() {
    let loggedIn = localStorage.getItem(environment.tokenName);

    if (loggedIn) {
      let account = this.getAccount();

      const epochNow = (new Date).getTime() / 1000;

      if (account.exp < epochNow) {
        loggedIn = null;
      }
    }

    return !!loggedIn;
  }

  login(token: string) {
    localStorage.setItem(environment.tokenName, token);
  }

  logout() {
    localStorage.removeItem(environment.tokenName);
    window.location.href = environment.ssoURL + `LogOff?redirect=` + environment.baseURL + environment.tokenRoute;
  }

  getAccount() {
    return jwt_decode<any>(localStorage.getItem(environment.tokenName) ?? "");
  }
}
