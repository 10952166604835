<mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      KESKU by 
      <img style="width: 50px;" src="https://dhonstudio.com/assets/img/logo_white.png">
        <!-- <mat-icon>home</mat-icon> -->
    </button>
    <span class="spacer"></span>
    <span>
        <!-- <mat-icon>payments</mat-icon> -->
        <!-- KESKU 2.0 -->
    </span>
    <span class="spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu" class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>person</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>assignment_ind</mat-icon>
        <span>{{name}} &nbsp;</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Log Out</span>
      </button>
    </mat-menu>
</mat-toolbar>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" [selectedIndex]="tabIndex" (selectedTabChange)="resetTab($event)">
  <mat-tab label="TRANSACTIONS">
    <app-transactions [search]="filter" (update)="updateAccount()" [accounts]="accounts"></app-transactions>
  </mat-tab>

  <!-- <mat-tab label="DASHBOARD">
    <app-dashboard></app-dashboard>
  </mat-tab> -->

  <mat-tab label="ACCOUNTS">
    <app-accounts (change)="doFilter($event)" (update)="updateTransaction()" (accounts)="initAccounts($event)"></app-accounts>
  </mat-tab>
</mat-tab-group>