import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Account, AccountType } from 'src/app/models/account.model';
import { AccountService } from 'src/app/services/account.service';
import { AccountService as AccountShared } from 'src/app/shared/account.service';
import { DialogEditAccountComponent } from './dialog-edit-account/dialog-edit-account.component';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  panelOpenState = false;

  accountType: AccountType[] = [];

  displayedColumns: string[] = ['name', 'total'];
  dataSource: Account[] = [];

  @Output() change = new EventEmitter<string>();

  total = "*****";
  totalTrue = 0;
  totalTrueFormatted = "";

  hide = true;

  @Output() update = new EventEmitter<string>();
  @Output() accounts = new EventEmitter<Account[]>();

  constructor(
    private accountService: AccountService,
    public dialog: MatDialog,
    private accountShared: AccountShared,
  ) { }

  ngOnInit(): void {
    this.accountService.getAccountType().subscribe(y => {
      this.accountType = y.data;

      var sortOrder = [0, 1, 6, 2, 3, 4, 5, 9, 8, 7];
      this.accountType.sort(function (a, b) {
        return sortOrder.indexOf(a.accountType) - sortOrder.indexOf(b.accountType);
      });

      this.accountService.getAccountsByUser().subscribe(x => {
        this.dataSource = x.data.accounts;
        this.accounts.emit(this.dataSource);
        this.totalTrue = x.data.totalAmounts;
        this.totalTrueFormatted = x.data.totalAmountsFormatted;
      });
    });
  }

  filterDataSource(accountType: number) {
    return this.dataSource.filter(x => x.accountType == accountType);
  }

  showTransactions(akunName: string) {
    this.change.emit(akunName);
  }

  editAccount(element: Account) {
    if (this.accountShared.getAccount().email !== 'viewer.mutis@email.com') {
      const dialogRef = this.dialog.open(DialogEditAccountComponent, {
        minWidth: '20%',
        maxWidth: '90%',
        width: '300px',
        data: {
          account: element,
          accountType: this.accountType
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data) {
          this.accountService.editAccount(result.data).subscribe(x => {
            this.ngOnInit();
            this.update.emit();
          });
        }
      });
    }
  }

}
